<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
const defaultLayout = 'default'
export default {
  computed: {
     layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    },
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
