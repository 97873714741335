import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from './plugins/axios.js'
import store from './store'

import './assets/scss/styles.scss'

Vue.config.productionTip = false
Vue.use(axios);

new Vue({
  computed: {
    isMobile: function () {
      return this.windowWidth < 768;
    },
    isTablet: function () {
      return this.windowWidth < 1024 && this.windowWidth > 767;
    },
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
