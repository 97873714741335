import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
function clearOldRequest(state) {
  state.requests.push(state.activeReq);
  state.activeReq = null;
}
export default new Vuex.Store({
  state: {
    user: {
      isAuth: false,
      data: {}
    },
    dashboard: {},
    requests: [],
    activeReq: null,
    statistic: {}
  },
  getters: {
    User: s => s.user.data,
    Dashboard: s => s.dashboard,
    Statistic: s => s.statistic
  },
  mutations: {
    addRequest: (state, req) => {
      state.activeReq = { cancel: req.cancel, url: req.url.split("?")[0] };
    },

    cancelReq(state) {
      state.activeReq.cancel();
      clearOldRequest(state);
    },

    requestSucceed(state) {
      clearOldRequest(state);
    },

    requestFailed(state) {
      clearOldRequest(state);
    },

    clearOldRequest,

    reset(state) {
      state.requests = [];
    },
    setUser(s, userData) {
      s.user.data = userData
    },
    setUserAuth(s, auth) {
      s.user.isAuth = auth
    },
    setDashboard(s, dashboard) {
      s.dashboard = dashboard
    },
    setStatistic(s, statistic) {
      s.statistic = statistic
    }
  },
  actions: {
    addRequest({ state, commit }, req) {
      if (state.activeReq && (state.activeReq.url === req.url.split("?")[0])) commit("cancelReq");
      commit("addRequest", req);
    },
    auth(ctx, token) {
      return this.$axios({
        method: "POST",
        url: "/api/v1/auth/",
        data: {'token': token}
      })
        .then(async (response) => {
          const result = response.data;
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh', result.refresh_token);
          this.$axios.defaults.headers.common.Authorization = `Bearer ${result.access_token}`;
          return await ctx.dispatch('getSession')
        })
        .catch((error) => {
          console.error(error);
          return error
        });
    },
    getSession(ctx) {
      return this.$axios({
        method: "GET",
        url: "/api/v1/me/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("setUser", result);
          ctx.commit("setUserAuth", true);
          return true
        })
        .catch((error) => {
          console.error(error);
          return error
        });
    },
    logoutUser(ctx) {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      delete this.$axios.defaults.headers.common.Authorization;
      ctx.commit("setUserAuth", false);
    },
    fetchDashboard(ctx) {
      return this.$axios({
        method: "GET",
        url: "/api/v1/dashboard/",
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("setDashboard", result);
          return true
        })
        .catch((error) => {
          console.error(error);
          return error
        });
    },
    fetchStatistic(ctx, filter) {
      return this.$axios({
        method: "GET",
        url: `/api/v1/statistics/?${filter.query}=${filter.value}`,
      })
        .then((response) => {
          const result = response.data;
          ctx.commit("setStatistic", result);
          return true
        })
        .catch((error) => {
          console.error(error);
          return error
        });
    },

  },
  modules: {
  }
})
