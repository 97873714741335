import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: load("Home"),
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: load("Statistic"),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: load("Profile"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  router.prevRoute = (from.name && router.prevRoute.name !== to.name) ? from : false;
  let search = to.hash.substring(1);
  let obj = {}
  if (search) {
    obj = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  }
  if (to.params && Object.keys(to.params).length) {
    return next()
  }
  if (obj.access_token) {
    return await store.dispatch("auth", obj.access_token).then((r) => {

      if (r == true) {

        return next({ name: "Home" })
      } else {

        if (r.response.status === 401) {
          window.open(`https://oauth.yandex.ru/authorize?response_type=token&client_id=0a042cf96e6d4f91a6423aada2e164da&redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT}`, '_self')
        } else {
          if (r.response.data.code && r.response.data.code[0] == 100) {
            return next({ name: to.name, params: { notFound: true } })
          } else {
            return next({ name: to.name, params: { error: true } })
          }

        }
      }
    })
  }
  if (localStorage.getItem('token')) {
    const isAuth = store.state.user.isAuth
    if (!isAuth) {
      return await store.dispatch("getSession").then((r) => {
        if (r == true) {
          return next()
        } else {
          if (r.response.status === 401) {
            window.open(`https://oauth.yandex.ru/authorize?response_type=token&client_id=0a042cf96e6d4f91a6423aada2e164da&redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT}`, '_self')
          } else {
            if (r.response?.data?.code[0] === 100) {
              return next({ name: to.name, params: { notFound: true } })
            } else {
              return next({ name: to.name, params: { error: true } })
            }
          }
        }
      })
    } else {
      return next()
    }
  } else {
    window.open(`https://oauth.yandex.ru/authorize?response_type=token&client_id=0a042cf96e6d4f91a6423aada2e164da&redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT}`, '_self')
    return next(false)
  }
})

export default router
